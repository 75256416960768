import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Modal, Avatar, Space, Input, Button, Select, Upload, message, Typography, Table, Popconfirm, Spin } from 'antd'
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

import axios from 'axios'
const { Text } = Typography

function FocusAreas() {
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)

  const [loading, setLoading] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const [focusAreas, setOrgFocusAreas] = useState([])
  const [referralCreatedFor, setReferralCreatedFor] = useState('')
  const [createFocusAreaModal, setCreateFocusAreaModal] = useState(false)
  const [title, setTitle] = useState('')
  const [fullForm, setFullForm] = useState('')

  const columns = [
    {
      title: 'Profile Picture',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon) => <Avatar src={icon && icon?.bucketResponse?.mediaLink} />,
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Full Form',
      dataIndex: 'fullForm',
      key: 'fullForm',
    },
    {
      title: 'Disabled',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (dis) => (dis ? 'TRUE' : 'FALSE'),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
          <span style={{ color: '#1677ff', cursor: 'pointer' }} onClick={() => navigate(`/focusArea/${record._id}`)}>
            Open
          </span>
          {/* <Popconfirm
            title="Are you sure to disable this?"
            description="All the sub categories will be deleted under this."
            onConfirm={() => {
              onDelete(record._id)
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <span style={{ color: 'red', cursor: 'pointer' }}>Disable</span>
          </Popconfirm> */}
        </Space>
      ),
    },
  ]

  const onDelete = async (focusId) => {
    const response = await axiosInstance.delete(`/api/v1/focus/deleteFocusArea?focusId=${focusId}`)
    alert('Success')
    window.location.reload()
  }

  const handleChange = (value) => {
    //
    setReferralCreatedFor(value)
    getFocusAreas(value)
  }

  const showModal = () => {
    if (!referralCreatedFor) {
      alert('Please select one first')
      return
    }
    setCreateFocusAreaModal(true)
  }

  const getMyInfo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/me`)

      // Check the admin organizations
      if (response?.data?.user?.organizations && response?.data?.user?.organizations.length > 0) {
        const org = response.data.user.organizations
        // console.log(org)

        // store to render at JSX
        org.forEach((element, idx) => {
          if (idx == 0) handleChange(element._id) // auto selecting the first organization to fetch some results
          setOrganizations((prevElements) => [
            ...prevElements,
            {
              value: element._id,
              label: element.name,
            },
          ])
        })
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getFocusAreas = async (v) => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/focus/getFocusAreas?organizationId=${v || referralCreatedFor}`)

      // console.log(response.data.focusAreas)
      setOrgFocusAreas(response.data.focusAreas)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const beforeUploadPictureUpload = (file, fileList) => {
    console.log(file)
    console.log(fileList)

    const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'

    if (!isImage) {
      message.error(`${file.name} is not a supported image type. Please upload JPEG or PNG files.`)
    }

    const isLt5M = file.size / 1024 / 1024 < 5 // Adjusted size limit to 5MB

    if (!isLt5M) {
      message.error('File must be smaller than 5MB!')
    }

    return isImage && isLt5M ? true : Upload.LIST_IGNORE
  }

  const uploadProfilePicture = async (options) => {
    if (!title || !fullForm) {
      alert('Please all fields are required')
      return
    }

    setLoading(true)

    const baseUrl = process.env.REACT_APP_BASE_URL

    const { onSuccess, onError, file, onProgress } = options
    const fmData = new FormData()

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100)
        // console.log(percent)
        // setImgProgress(percent)
        // if (percent === 100) {
        //   setTimeout(() => setImgProgress(0), 1000)
        // }
        onProgress({ percent: (event.loaded / event.total) * 100 })
      },
    }
    fmData.append('title', title)
    fmData.append('fullForm', fullForm)
    fmData.append('iconPicture', file)

    try {
      const res = await axios.post(`${baseUrl}/api/v1/focus/createFocusArea?for=${referralCreatedFor}`, fmData, config)

      console.log(res.data)

      alert('Success')
      window.location.reload()
      setLoading(false)
      onSuccess('Ok')
    } catch (err) {
      // onSuccess('Ok')
      // return
      console.log(err)
      alert('Failed to upload, something went wrong.')
      console.log('Eroor: ', err)
      const error = new Error('Some error')
      onError({ err })
    }
  }

  useEffect(() => {
    getMyInfo()
  }, [])

  return (
    <Layout>
      <Modal
        title="Add Focus Area"
        open={createFocusAreaModal}
        footer={null}
        onCancel={() => {
          setCreateFocusAreaModal(false)
        }}
      >
        <Input placeholder="Enter the title" style={{ marginBottom: 10 }} onChange={(e) => setTitle(e.target.value)} />

        <Input placeholder="Enter full form" onChange={(e) => setFullForm(e.target.value)} />
        <br />
        <br />
        {title && (
          <Upload.Dragger
            // style={{ height: 100 }}
            name="logo"
            listType="picture"
            maxCount={1}
            beforeUpload={beforeUploadPictureUpload}
            customRequest={uploadProfilePicture}
          >
            <Button type="text" icon={<UploadOutlined />}>
              Click to upload or drag here
            </Button>
          </Upload.Dragger>
        )}
      </Modal>
      <ArrowLeftOutlined
        style={{
          color: '#1677ff',
          fontSize: '1.5rem',
          marginBottom: 20,
          cursor: 'pointer',
        }}
        onClick={() => navigate('/home')}
      />
      {organizations.length > 0 && (
        <div>
          <p>Choose organization for which the Focus Area is for</p>
          <Select
            placeholder="Please select club or business"
            style={{
              width: 250,
              marginRight: 10,
            }}
            defaultValue={organizations[0].value}
            onChange={handleChange}
            options={organizations}
          />
          {/* <Button type="primary" onClick={showModal}>
            Create Focus Area
          </Button> */}
        </div>
      )}

      <br />
      {loading && <Spin size="large" />}
      <Table
        scroll={{
          x: 1300,
        }}
        dataSource={focusAreas}
        columns={columns}
        rowKey="_id"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/focusArea/${record._id}`)
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          }
        }}
      />
    </Layout>
  )
}

export default FocusAreas
