import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'

import Auth from './auth/Auth'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Referrals from '../pages/Referrals'
import Profile from '../pages/Profile'
import Logs from '../pages/Logs'
import Members from '../pages/Members'
import Member from '../pages/Member'
import FocusAreas from '../pages/FocusAreas'
import FocusArea from '../pages/FocusArea'
import EditFocusArea from '../pages/EditFocusArea'

function R() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Authenticated route below */}
        <Route path="/" element={<Auth children={<Outlet />} />}>
          <Route path="" element={<Home />}></Route>
          <Route path="home" element={<Home />}></Route>
          <Route path="referrals" element={<Referrals />}></Route>
          <Route path="profile" element={<Profile />}></Route>
          <Route path="logs" element={<Logs />}></Route>
          <Route path="members" element={<Members />}></Route>
          <Route path="member/:id" element={<Member />}></Route>
          <Route path="focusAreas" element={<FocusAreas />}></Route>
          <Route path="FocusArea/:id" element={<FocusArea />}></Route>
          <Route path="editFocusArea/:parentId/:childId" element={<EditFocusArea />}></Route>
        </Route>

        {/* Public routes below */}
        <Route exact path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  )
}

export default R
