import React, { useState, useRef, useEffect } from 'react'
import { Table, Spin, Avatar } from 'antd'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function Logs() {
  const [loading, setLoading] = useState(false)
  const [logs, setLogs] = useState([])

  const getLogs = async (v) => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/logs/getLogs`)

      setLogs(response.data.logs)
      // console.log(response.data.logs)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getLogs()
  }, [])

  const columns = [
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => (
        <div>
          {record.userDetails?.profilePicture && <Avatar src={record.userDetails?.profilePicture} style={{ margin: 20 }} />}
          {record.message}
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ]

  return (
    <Layout>
      <div style={{ padding: '20px' }}>
        <h2>Logs</h2>
        {loading ? (
          <Spin />
        ) : (
          <>
            <p>Count: {logs.length}</p>

            <Table columns={columns} dataSource={logs} pagination={{ pageSize: 5 }} scroll={{ x: 300 }} />
          </>
        )}
      </div>
    </Layout>
  )
}

export default Logs
