// axiosInstance.js
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Replace with your API base URL
  // timeout: 5000, // after 5 seconds the request will be cancelled if response is doing any delay
  headers: {
    // 'Content-Type': 'application/json',
    // 'Content-Type': 'multipart/form-data',
  },
})

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the request configuration here if needed
    // For example, add an authorization header
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here if needed
    return response
  },
  (error) => {
    if (error.response) {
      console.error('Response Error Status:', error.response.status)
      console.error('Response Data:', error.response.data)
      if (error?.response?.data?.suggestedAction === `logout`) {
        // if server tells to logout then logout the user
        console.log('Website logout should be here  🔴🔴🔴')
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('user')
        window.location.reload()
      }
    } else if (error.request) {
      console.error('No Response Received')

    } else {
      console.error('Request Error:', error.message)
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
